import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Button, Row, Col, Container} from 'react-bootstrap';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import DomainHero from '../../components/domain-hero';
import StudyCard from '../../components/card-study';
import CardAppPeCan from '../../components/card-app-pecan';
import CardAppPlatform from '../../components/card-app-platform';
import CardAppVizComm from '../../components/card-app-vizcomm';
import CardAppModelSystems from '../../components/card-app-model-systems';

const PediatricCancer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_genomics-platform.png"}) {
        id
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Pediatric Cancer" />
      <div className="pediatric-cancer-domain">
        <DomainHero
          fileName="dyer.png"
          caption="Catalyzing discovery for pediatric cancer."
          label="Michael Dyer, Ph.D."
        />
        <div>
          <Section className="desc-section">
            <Row>
              <Col>
                <h1>Pediatric Cancer</h1>
                <p className="fw-bold">Welcome to the Pediatric Cancer Research Domain</p>
                <p>
                  Here, we have gathered all you need to know about pediatric cancer on St. Jude
                  Cloud. Use this page to browse our pediatric cancer studies, which are the
                  different datasets we offer on St. Jude Cloud, or discover the possibilities of
                  data curation with our list of recommended apps for working with pediatric cancer
                  data. Get updates and important links on this page to know what St. Jude Cloud is
                  up to next!
                </p>
              </Col>
            </Row>
          </Section>

          <Container fluid className="featured__container">
            <Container fluid className="featured__icon__container">
              <Row className="featured__icon__row">
                <Col md={12} className="featured__icon__col">
                  <Img fixed={data.file.childImageSharp.fixed} className="featured__icon" />
                </Col>
              </Row>
            </Container>

            <Section gray>
              <Row>
                <Col md={{span: 11, offset: 1}} className="featured__icon__col--responsive">
                  <Img fixed={data.file.childImageSharp.fixed} className="featured__icon" />
                </Col>
                <Col md={{span: 11, offset: 1}}>
                  <strong>FEATURED DATA</strong>
                  <h4 className="featured__header">Pediatric Tumor Gene Expression Data</h4>
                  <p>
                    St. Jude Cloud offers gene expression (gene count) data generated from our
                    custom analysis of tumor sample RNA-seq data for over 2,000 pediatric tumor
                    samples. This expression data is freely available to all users of St. Jude
                    Cloud. A wide range of pediatric tumor subtypes are represented within this data
                    collection and include samples from our paired tumor-normal genomic studies
                    including the Pediatric Cancer Genome Project, Clinical Pilot Study, Genomes for
                    Kids Study, and Real-Time Clinical Genomics Initiative.
                  </p>
                  <Button
                    href="https://platform.stjude.cloud/data/cohorts?file_types=FEATURE_COUNTS&select_all"
                    variant="pediatric-cancer"
                  >
                    Access Data
                  </Button>
                </Col>
              </Row>
            </Section>
          </Container>

          <Section>
            <Row>
              <Col xs={12}>
                <h2>Apps</h2>
                <p>
                  St. Jude Cloud is comprised of multiple applications that allow you to interact
                  with our data in different ways.
                </p>
              </Col>
            </Row>
            <Row>
              <CardAppPlatform
                lg={6}
                url="https://platform.stjude.cloud/data/cohorts/pediatric-cancer"
              />
              <CardAppPeCan lg={6} />
              <CardAppVizComm
                lg={6}
                url="https://viz.stjude.cloud/visualizations?research-domain=pediatric-cancer"
              />
              <CardAppModelSystems lg={6} />
            </Row>
          </Section>

          <Section gray>
            <Row className="mb-3">
              <Col xs={12}>
                <h2>Studies</h2>
                <p>
                  We offer data from several pediatric cancer studies, from the Pediatric Cancer
                  Genome Project to Clinical Genomics. Learn about the WGS data provided to academic
                  researchers through St. Jude Cloud.
                </p>
              </Col>
            </Row>
            <Row className="studies__list">
              <StudyCard
                title="Pediatric Cancer Genome Project"
                url="/studies/pediatric-cancer-genome-project"
              />
              <StudyCard title="Clinical Genomics" url="/studies/clinical-genomics" />
            </Row>
          </Section>
        </div>
      </div>
    </Layout>
  );
};

export default PediatricCancer;
